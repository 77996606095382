interface Price {
    i18nKey: string,
    price: number,
}

const PRICES: Price[] = [
    {
        i18nKey: "prices.lightCuredFilling",
        price: 250,
    },
    {
        i18nKey: "prices.scaling",
        price: 150,
    },
    {
        i18nKey: "prices.scalingSandingFluoridation",
        price: 250,
    },
    {
        i18nKey: "prices.overlayWhitening",
        price: 800,
    },
    {
        i18nKey: "prices.laserTreatment",
        price: 250,
    },
    {
        i18nKey: "prices.laserTreatmentLipHerpes",
        price: 100,
    },
    {
        i18nKey: "prices.canal1",
        price: 450,
    },
    {
        i18nKey: "prices.canals2-4",
        price: 550,
    },
    {
        i18nKey: "prices.porcelainCrown",
        price: 1000,
    },
    {
        i18nKey: "prices.acrylicPartDenture",
        price: 1500,
    },
    {
        i18nKey: "prices.completeAcrylicDenture",
        price: 1800,
    },
    {
        i18nKey: "prices.skeletalProsthesis",
        price: 2000,
    },
    {
        i18nKey: "prices.flexibleDenture",
        price: 2200,
    },
    {
        i18nKey: "prices.botoxBetweenEyebrows",
        price: 600,
    },
    {
        i18nKey: "prices.botoxForehead",
        price: 550,
    },
    {
        i18nKey: "prices.botoxEyes",
        price: 550,
    },
];

export const DEFAULT_CURRENCY: string = "PLN";

export default PRICES;