interface AdmissionHour {
    i18nWeekdayKey: string,
    i18nOpeningHourKey?: string,
    i18nClosingHourKey?: string,
    closed?: boolean
}

const ADMISSION_HOURS: AdmissionHour[] = [
    {
        i18nWeekdayKey: "admissionHours.monday",
        i18nOpeningHourKey: "admissionHours.2pm",
        i18nClosingHourKey: "admissionHours.7pm",
    },
    {
        i18nWeekdayKey: "admissionHours.tuesday",
        closed: true,
    },
    {
        i18nWeekdayKey: "admissionHours.wednesday",
        i18nOpeningHourKey: "admissionHours.2pm",
        i18nClosingHourKey: "admissionHours.7pm",
    },
    {
        i18nWeekdayKey: "admissionHours.thursday",
        i18nOpeningHourKey: "admissionHours.2pm",
        i18nClosingHourKey: "admissionHours.7pm",
    },
    {
        i18nWeekdayKey: "admissionHours.friday",
        closed: true,
    },
];

export default ADMISSION_HOURS;