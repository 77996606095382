import React from "react";
import {Carousel, Container, Image, Nav, Navbar, NavDropdown, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Facebook} from "react-bootstrap-icons";
import PRICES, {DEFAULT_CURRENCY} from "./Prices";
import ADMISSION_HOURS from "./AdmissionHours";
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import {Icon} from "leaflet";

function App() {
    const {t, i18n} = useTranslation();

    const flagPl = require("./images/poland.png");
    const flagUk = require("./images/united-kingdom.png");
    const booksyLogo = require("./images/booksy.png");
    const markerIconPng = require("leaflet/dist/images/marker-icon.png");
    const position = {
        lat: 54.3421026,
        lng: 18.5511175
    };

    return (
        <Container fluid>
            <Navbar
                expand={"lg"}
                className={"border-bottom navbar"}
                id={"home"}
                sticky={"top"}
            >
                <Container>
                    <Navbar.Brand
                        href={"#home"}
                    >
                        <img
                            className={"img-fluid header-image"}
                            src={require("./images/logo.png")}
                            alt={"Logo"}
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle/>
                    <Navbar.Collapse>
                        <Nav
                            className={"mx-auto align-items-center"}
                        >
                            <Nav.Link
                                href={"#aboutUs"}
                                className={"px-4"}
                            >
                                {t("header.aboutUs")}
                            </Nav.Link>
                            <Nav.Link
                                href={"#offer"}
                                className={"px-4"}
                            >
                                {t("header.offer")}
                            </Nav.Link>
                            <Nav.Link
                                href={"#priceList"}
                                className={"px-4"}
                            >
                                {t("header.priceList")}
                            </Nav.Link>
                            <Nav.Link
                                href={"#admissionHours"}
                                className={"px-4"}
                            >
                                {t("header.admissionHours")}
                            </Nav.Link>
                            <Nav.Link
                                href={"#contactUs"}
                                className={"px-4"}
                            >
                                {t("header.contactUs")}
                            </Nav.Link>
                            <Nav.Link
                                href={"#emergency24"}
                                className={"px-4"}
                            >
                                {t("header.emergency24")}
                            </Nav.Link>
                            <Nav.Link
                                target={"_blank"}
                                className={"px-4 py-0 header-image"}
                                href={"https://www.facebook.com/Gabinet-Stomatologiczny-Lekarz-Dentysta-Ewa-Tomczak-292415004118842"}
                            >
                                <Facebook size={40}/>
                            </Nav.Link>
                            <Nav.Link
                                target={"_blank"}
                                className={"px-4"}
                                href={"https://booksy.com/pl-pl/147156_gabinet-stomatologiczny_stomatolog_20383_gdansk"}
                            >
                                <Image
                                    className={"header-image"}
                                    src={booksyLogo}
                                    alt={"Booksy logo"}
                                    roundedCircle
                                />
                            </Nav.Link>
                            <NavDropdown
                                className={"px-4"}
                                title={
                                    <>
                                        <Image
                                            src={i18n.language === "pl" ? flagPl : flagUk}
                                            alt={"Current language flag"}
                                        />
                                        <span className={"ms-2"}>{t("header.selectLanguage")}</span>
                                    </>
                                }
                            >
                                <NavDropdown.Item
                                    onClick={() => i18n.changeLanguage("pl")}
                                >
                                    <Image
                                        src={flagPl}
                                        alt={"PL flag"}
                                    /> Polski
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={() => i18n.changeLanguage("en")}
                                >
                                    <img
                                        src={flagUk}
                                        alt={"UK flag"}
                                    /> English
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Container
                className={"mt-2"}
            >
                <Carousel>
                    <Carousel.Item
                        interval={2000}
                    >
                        <img
                            className={"d-block w-100"}
                            src={require("./images/gabinet1.png")}
                            alt={"Zdjęcie gabinetu"}
                        />
                    </Carousel.Item>
                    <Carousel.Item
                        interval={2000}
                    >
                        <img
                            className={"d-block w-100"}
                            src={require("./images/gabinet2.png")}
                            alt={"Zdjęcie budynku"}
                        />
                    </Carousel.Item>
                </Carousel>
            </Container>
            <Container
                className={"mt-5"}
                id={"aboutUs"}
            >
                <h1 className={"border-bottom"}>
                    {t("header.aboutUs")}
                </h1>
                <p>
                    {t("aboutUs")}
                </p>
            </Container>
            <Container
                className={"mt-5"}
                id={"offer"}
            >
                <h1 className={"border-bottom"}>
                    {t("header.offer")}
                </h1>
                <p>
                    {t("offer.description")}
                </p>
                <ul>
                    <li>
                        {t("offer.surgery")}
                    </li>
                    <ul>
                        <li>
                            {t("offer.extractions")}
                        </li>
                    </ul>
                    <li>
                        {t("offer.conservativeDentistryEndodontics")}
                    </li>
                    <ul>
                        <li>
                            {t("offer.cariesProphylaxis")}
                        </li>
                        <li>
                            {t("offer.treatmentToothSensitivity")}
                        </li>
                        <li>
                            {t("offer.treatmentReconstruction")}
                        </li>
                        <li>
                            {t("offer.shapeCorrection")}
                        </li>
                        <li>
                            {t("offer.chamberReconstruction")}
                        </li>
                        <li>
                            {t("offer.endodontics")}
                        </li>
                    </ul>
                    <li>
                        {t("offer.prosthetics")}
                    </li>
                    <ul>
                        <li>
                            {t("offer.crownsBridges")}
                        </li>
                        <li>
                            {t("offer.adhesiveComposite")}
                        </li>
                        <li>
                            {t("offer.partialDentures")}
                        </li>
                        <li>
                            {t("offer.skeletalDentures")}
                        </li>
                        <li>
                            {t("offer.immediateDentures")}
                        </li>
                    </ul>
                    <li>
                        {t("offer.childrenDentistry")}
                    </li>
                    <ul>
                        <li>
                            {t("offer.milkImpregnation")}
                        </li>
                        <li>
                            {t("offer.decidousTeethTreatment")}
                        </li>
                        <li>
                            {t("offer.fluoridation")}
                        </li>
                        <li>
                            {t("offer.permanentTeethTreatment")}
                        </li>
                        <li>
                            {t("offer.sealing")}
                        </li>
                        <li>
                            {t("offer.varnishing")}
                        </li>
                        <li>
                            {t("offer.childrenExtraction")}
                        </li>
                    </ul>
                    <li>
                        {t("offer.periodontology")}
                    </li>
                    <ul>
                        <li>
                            {t("offer.ultrasonicDescaling")}
                        </li>
                        <li>
                            {t("offer.sandblasting")}
                        </li>
                    </ul>
                    <li>
                        {t("offer.cosmeticDentistry")}
                    </li>
                    <ul>
                        <li>
                            {t("offer.whitening")}
                        </li>
                        <li>
                            {t("offer.compositeVeneers")}
                        </li>
                        <li>
                            {t("offer.cosmeticCrowns")}
                        </li>
                        <li>
                            {t("offer.inlaysOnlays")}
                        </li>
                    </ul>
                </ul>
            </Container>
            <Container
                className={"mt-5"}
                id={"priceList"}
            >
                <h1 className={"border-bottom"}>
                    {t("header.priceList")}
                </h1>
                <p>
                    {t("prices.description")}
                </p>
                <Table striped>
                    <thead>
                    <tr>
                        <th>{t("prices.service")}</th>
                        <th>{t("prices.price")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {PRICES.map((price, index) =>
                        <tr key={index}>
                            <td>{t(price.i18nKey)}</td>
                            <td>{`${t(price.price.toString())} ${DEFAULT_CURRENCY}`}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </Container>
            <Container
                className={"mt-5"}
                id={"admissionHours"}
            >
                <h1 className={"border-bottom"}>
                    {t("header.admissionHours")}
                </h1>
                <Table striped>
                    <thead>
                    <tr>
                        <th>{t("admissionHours.day")}</th>
                        <th>{t("admissionHours.openingHours")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ADMISSION_HOURS.map((admissionHour, index) =>
                        <tr key={index}>
                            <td>{t(admissionHour.i18nWeekdayKey)}</td>
                            {admissionHour.closed ?
                                <td>{t("admissionHours.closed")}</td> :
                                <td>{`${t(admissionHour.i18nOpeningHourKey!)} - ${t(admissionHour.i18nClosingHourKey!)}`}</td>
                            }
                        </tr>
                    )}
                    </tbody>
                </Table>
                <p>{t("admissionHours.annotation")}</p>
            </Container>
            <Container
                className={"mt-5"}
                id={"contactUs"}
            >
                <h1 className={"border-bottom"}>
                    {t("header.contactUs")}
                </h1>
                <p>{t("contact.name")} <br/>
                    lek. stom. Ewa Tomczak <br/>
                    ul. Kartuska 404 <br/>
                    80-125 Gdańsk <br/>
                    Email: <a href={"mailto:iplewatomczak@onet.pl"}>iplewatomczak@onet.pl</a>
                </p>
                <MapContainer
                    center={position}
                    zoom={18}
                    scrollWheelZoom={false}
                    className={"map"}
                >
                    <TileLayer
                        attribution={"&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors"}
                        url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
                    />
                    <Marker
                        position={position}
                        icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}
                    />
                </MapContainer>
            </Container>
            <Container
                className={"mt-5"}
                id={"emergency24"}>
                <h1 className={"border-bottom"}>
                    {t("header.emergency24")}
                </h1>
                <p>
                    {t("ambulance24.message")} <b>{t("contact.phoneNumber")}</b>
                </p>
            </Container>
        </Container>
    );
}

export default App;
